
import { Component, Prop, Vue } from 'vue-property-decorator'
import { bus } from 'shared/state/Bus'
import { ThirdPartyAPIStatusType } from 'shared/types'
import { formatBytes, } from 'shared/util/charts'
import axios from 'axios'
const https = require('https')


@Component({ components: {  } })
export default class ThirdPartyAPI extends Vue {

	status: ThirdPartyAPIStatusType | null = null
	health = 'unknown'

	vml(val: any, key?: string | null) {
	if(val.constructor === Array) {
		return '<div><div role="group" class="form-inline form-group">' +
		'<div style="min-width: 50%;font-weight: bold;text-align:right;">' + key + ':</div><div>' +
		'<input type="text" class="tb ml-2 mb-1 form-control forminline" readonly value="' + (val.length ? val : 'none') + '"></div></div></div>'
	}
	switch(typeof(val)) {
		case 'object': {
			let s = key ? '<div class="card text-center mb-4">' +
					'<div class="card-header bg-info text-white font-weight-bold">' + key + '</div>' +
					'<div class="card-body">' : ''
			for (const [k, v] of Object.entries(val)) {
  				s += this.vml(v, k)
			}
			return s + '</div></div>'
		}
		case 'boolean': {
			return '<div class="custom-control custom-checkbox mb-2">' +
			'		<input type="checkbox" class="custom-control-input" value="' +
			val + '" ' + (val ? 'checked' : '') + '>' +
					'<label class="custom-control-label text-' + (!val ? 'danger' : 'success') + '">' + key + '</label></div>'
		}
		default: {
			return '<div><div role="group" class="form-group form-inline">' +
			'<div style="min-width: 50%;font-weight: bold;text-align:right;">' + key +
			':</div><div><input type="text" class="tb ml-2 mb-1 form-control" readonly value=' +
			val + '></div></div></div>'
		}
	}
	}


handleStatus(status: ThirdPartyAPIStatusType) {
	this.status = status
	this.health = (JSON.stringify(status).includes('"health": false')) ? 'bad' : 'good'
}

created() {
		bus.$on('third_party_api_status', this.handleStatus)
	}

beforeDestroy() {
		bus.$off('third_party_api_status', this.handleStatus)
	}

}

