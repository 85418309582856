
import { Component, Prop, Vue } from 'vue-property-decorator'
import ServerMonitor from 'shared/components/ServerMonitor.vue'
import InstancesTable from './InstancesTable.vue'
import ServerArea from './ServerArea.vue'
// import ServerRace from 'shared/components/ServerRace.vue'
import { ErgModule } from 'shared/state/Erg'
import { bus } from 'shared/state/Bus'
import { ServerModule } from 'shared/state/Server'
// import { MonitorClass } from 'shared/state/Monitor'
import { RemoteControlClass } from 'shared/state/RemoteControl'
import { RemoteErgModule } from 'shared/state/RemoteErg'
import { makeComputedBinding } from 'shared/util'
import { setCookie, getCookie } from 'shared/util/cookie'
import { LogBookUser, LogBookUserState } from 'shared/state/LogBookUser'
import CompetitionDetail from './CompetitionDetail.vue'
import ServiceDetail from './ServiceDetail.vue'
import { isNull } from 'lodash'
import { BIconExclamationSquareFill } from 'bootstrap-vue'
import WebServers from './WebServers.vue'
import ThirdPartyAPI from './ThirdPartyAPI.vue'

const axios = require('axios').default

interface RaceServerType {
		ip: string
		name: string
		type: 'general' | 'test' | 'dev'
}

@Component({ components: { InstancesTable, ServerArea, CompetitionDetail, ServiceDetail, WebServers, ThirdPartyAPI } })
export default class Main extends Vue {

	servers
	MonitorServers: any[] = []
	RaceServers: any[] = []
	timer
	competition = {}
	service = {}
	serverUrl = ''
	showCompetitionDetail(serverUrl, competition: any) {
		this.competition = competition
		this.serverUrl = serverUrl
	}
	showServiceDetail(serverUrl, service: any) {
		this.service = service
		this.serverUrl = serverUrl
	}

	async created() {
		bus.$on('competition_detail', this.showCompetitionDetail)
		bus.$on('service_detail', this.showServiceDetail)
		this.servers = LogBookUser.raceServers
		this.servers.forEach(s => {
			const abbr = s.name.toString().split('.')
			if(abbr[0]) {
				s.url = 'https://' + s.name
				s.wss = 'wss://admin.ergrace.com/secure-socket/' + abbr[0] + '/60000'
				s.ws = 'wss://admin.ergrace.com/socket/' + abbr[0]
				const m = new ServerMonitor({propsData: { wsUrl: s.wss }})
				this.MonitorServers.push({server: s, monitor: m, ergs:[], hps: [] })
			}
		})
		// for local testing
		// const s = {ip: '', name: 'localhost', type: 'dev',
		// url: 'https://localhost', wss: 'wss://localhost:60000', ws: 'ws://localhost:60000'}
		// this.servers.push({ip: '', name: 'localhost', type: 'dev'})
		// const m = new ServerMonitor({propsData: { wsUrl: s.wss }})
		// this.MonitorServers.push({server: s, monitor: m, ergs:[], hps: [] })
		bus.$on('got_competition_instances', this.connectToErgsAndHPs)
	}

	beforeDestroy() {
		bus.$off('got_competition_instances', this.connectToErgsAndHPs)
		bus.$off('competition_detail', this.showCompetitionDetail)
		bus.$off('service_detail', this.showServiceDetail)
	}
	connectToErgsAndHPs(aServer: any, competitionInstances: any[]) {
		if(!competitionInstances) { return }
		const foundServer = this.MonitorServers.find(s => s.server.name === aServer.name)
		if(foundServer) {
			competitionInstances.forEach(i => {
				const foundErg = foundServer.ergs.find(rs => rs.erg_port === i.erg_port)
				const foundHP = foundServer.hps.find(rs => rs.hp_port === i.hp_port)
				if(!foundErg) {
					const s = new ServerModule()
					const erg = new ErgModule()
					const rc = new RemoteControlClass({propsData: { Server: s}})

					s.setWsUrl(`${aServer.ws}/${i.erg_port}`)
					s.connect().then(() => {
						erg.attachToServer(s)
						// rc.remoteErgAction('connect_display')
						rc.remoteErgAction('get_connections')
						rc.remoteErgAction('get_version')

						this.timer = setInterval(async () => {
								rc.remoteErgAction('get_connections')
								rc.remoteErgAction('get_version')
						}, 5000)
					})

					foundServer.ergs.push({ erg_port: i.erg_port, ergrace: { server: s, erg, remote_control: rc }})

					if(!foundHP) {
						const s2 = new ServerModule()
						const hp = new ErgModule()
						const rc2 = new RemoteControlClass({propsData: { Server: s2}})
						s2.setWsUrl(`${aServer.ws}/${i.hp_port}`)
						s2.connect().then(() => {
							hp.attachToServer(s2)
							rc2.remoteErgAction('get_connections')
							rc2.remoteErgAction('get_version')

							this.timer = setInterval(async () => {
								rc2.remoteErgAction('get_connections')
								rc2.remoteErgAction('get_version')
							}, 5000)
							})
						foundServer.hps.push({ hp_port: i.hp_port, hp: { server: s2, hp, remote_control: rc2 }})
					}
				}
			})
		}
	}
}

