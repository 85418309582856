
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { bus } from 'shared/state/Bus'
import { getCookie } from 'shared/util/cookie'
import { LogBookUser, LogBookUserState } from 'shared/state/LogBookUser'
import { LogBookUserDefinition, LogBookCredentials, Instance, CompetitionInstance, RaceServer, AdminUser } from 'shared/types/logBook'
import Announcement from './Announcement.vue'

const axios = require('axios').default

@Component({ components: { Announcement } })
export default class WebServer extends Vue {
	@Prop() monitorServers!: any[]
	@Prop() webServer!: string


	announcement = ''
	original = ''
	lastUpdated = ''
	saving = false

	save() {
		this.saving = true
		const d: any =  {
			WEB_SERVERS: { [this.webServer]: { ANNOUNCEMENT : this.announcement}}
		}
		this.monitorServers.forEach(s => {
				s.monitor.monitor.server.send({ action: 'settings', detail: d})
		})
	}

handleBusSettings(s) {
	this.saving = false
	this.original = this.announcement
	if(this.webServer && s.WEB_SERVERS && s.WEB_SERVERS[this.webServer]
		&& s.WEB_SERVERS[this.webServer].ANNOUNCEMENT && s.LAST_UPDATED) {
			// only accept the most current change
			if(this.lastUpdated < s.LAST_UPDATED) {
				this.announcement = s.WEB_SERVERS[this.webServer].ANNOUNCEMENT
				this.lastUpdated = s.LAST_UPDATED
				// delay to allow control settle in by inserting wrapping <p> tags
				setTimeout(() => this.original = this.announcement, 500)
			}
		}
	}

created() {
	bus.$on('got_settings', this.handleBusSettings)
  }

beforeDestroy() {
	  bus.$off('got_settings', this.handleBusSettings)
  }
}


