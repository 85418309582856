
import { Component, Prop, Vue } from 'vue-property-decorator'
// import ServerMonitor from 'shared/components/ServerMonitor.vue'
import InstancesTable from './InstancesTable.vue'
import Memory from './Memory.vue'
import Disk from './Disk.vue'
import Instances from './Instances.vue'
import Config from './Config.vue'
import { Server } from 'https'
import Pm2Services from './Pm2Services.vue'
import { DiskStatusType } from 'shared/types'
import { bus } from 'shared/state/Bus'


@Component({ components: { Memory, Disk, Instances, InstancesTable, Config, Pm2Services } })
export default class ServerArea extends Vue {
	@Prop() server!: any
	@Prop() serverMonitor!: any
	services: any = []
	filesystems: string[] = []

	servicesChanged(e, apiVersion, monitorVersion) {
		e.forEach(s => {
			s.name = s.name.toString().replace('@jessety/pm2-logrotate', 'PM2 Windows Service')
			if(!s.api_version) { s.api_version = apiVersion || '0.0' }
			if(!s.monitor_version) { s.monitor_version = monitorVersion || '0.0'}
		})
		this.services = e
	}
	diskStatus(disk_status: DiskStatusType, socket: string) {
		if(!this.filesystems.includes(disk_status.filesystem) && socket === this.server.wss) {
			this.filesystems.push(disk_status.filesystem)
		}
	}
	created() {
		bus.$on('disk_status', this.diskStatus)
	}

	beforeDestroy() {
		bus.$off('disk_status', this.diskStatus)
	}
}

