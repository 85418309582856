
import { Component, Prop, Vue } from 'vue-property-decorator'
import { LogBookUser, LogBookUserState } from 'shared/state/LogBookUser'


@Component({ components: {  } })
export default class LoginForm extends Vue {
	@LogBookUserState() apiEndpoint!: string

	form = {
		username: '',
		password: '',
		access_code: ''
	}
	errorMessage = ''

	show: boolean = true
	spinning: boolean = false

	get disabled(): boolean {
		return !(this.apiEndpoint.length > 0)
	}

	clearError() {
		this.errorMessage = ''
	}

	async onSubmit(evt) {
		this.errorMessage = ''
		this.spinning = true
		evt.preventDefault()

		try {
			await LogBookUser.login(this.form.username, this.form.password)
			if(!LogBookUser.isAdmin) {
				LogBookUser.logout()
				throw(999)
			}
		} catch (err) {
			if (err.message === 'Network Error') {
				this.errorMessage = `Unable to reach Concept 2.
					Please check your internet connection.
					If issue persists, please contact C2 tech support.`
			} else if (err === 401) {
				// unauthorized
				this.errorMessage =
					'Invalid Username or Password. Please correct and resubmit or contact C2 tech support if issue persists.'
			} else if (err === 999) {
				// unauthorized
				this.errorMessage =
					'You are not authorized to use this application. Please contact C2 tech support to request access.'
			} else {
				// unknown error
				this.errorMessage =
					`An error occurred. Please contact C2 tech support.`
				console.error(err)
			}
		}
		this.spinning = false
	}
}
