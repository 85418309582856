
import { isNull } from 'lodash'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { epochElapsedTime } from 'shared/util/formatTime'
import ServicePerformance from './ServicePerformance.vue'

@Component({ components: { ServicePerformance} })
export default class CompetitionDetail extends Vue {
  @Prop() competition!: any
  @Prop() serverUrl!: string
  background = 'white'

  val(prop: string, value: number) {
  return (prop === 'uptime') ? epochElapsedTime(value) : value.toString()
  }

  get serverAbbr() {
  if (!this.serverUrl) { return '' }
  const match = this.serverUrl.toString().match(/\w+/gi)
  return match ? match[1].toUpperCase() || '' : ''
  }

  get competitionStatKeys(): string[] {
  const keys = this.competition ? Object.keys(this.competition).sort() : []
  return keys.filter(k => !['versions', 'hp_data', 'er_data', 'ports', 'pretty_code'].includes(k))
  }
  get pmDataKeys() {
  if (this.competition?.er_data) { return Object.keys(this.competition.er_data) }
  if (this.competition?.hp_data) { return Object.keys(this.competition.hp_data) }
  return []
  }

  get statsHtml() {
	return this.competition?.code || ''
  }
  log(l: string, option?: string) {
  switch (l) {
  case 'er': window.open(`${this.serverUrl}/download/${this.competition.code}/ergrace`, `_blank`)
				         break
  case 'hp': window.open(`${this.serverUrl}/download/${this.competition.code}/holding_pen`, `_blank`)
				         break
  case 'api': window.open(`${this.serverUrl}/download/logs/api/${option}`, `_blank`)
				          break
  case 'monitor': window.open(`${this.serverUrl}/download/logs/monitor`, `_blank`)
					             break
  case 'daemon': window.open(`${this.serverUrl}/download/logs/daemon`, `_blank`)
					            break
	}
  }

  @Watch('competition', { deep: true })
  competitionUpdating(newVal) {
	this.background = 'light-gray'
	setTimeout(() => { this.background = 'white' }, 1000)
  }
}
