
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import { ServerModule, MonitorClass } from 'shared/state'

import Reconnect from './Reconnect.vue'
import Spinner from './Spinner.vue'

@Component({ components: { Reconnect, Spinner } })
export default class ServerMonitor extends Vue {

	server = new ServerModule()
	monitor: MonitorClass |  number = 0

	@Prop({ default: 'en-US' }) lang!: string
	@Prop() wsUrl!: string // includes port

	ergs: any[] = []
	hps: any[] = []


	@Watch('lang')
	languageChange(newLang: string) {
		Vue.loadLang(newLang)
	}

	created() {
		this.connect()
	}

	connect() {
		if (this.ws && this.server.state !== 'connected') {
			this.server.setWsUrl(this.ws)
		}
		this.server.connect().then(() => {
			this.$emit('ready')
			this.monitor = new MonitorClass({propsData: { Server: this.server}})

		})
	}

	beforeDestroy() {
		this.server.disconnect()
	}

	get ws() {
		return this.wsUrl
	}
}
