
import { Component, Prop, Vue } from 'vue-property-decorator'
import HeaderLinkAccount from './HeaderLinkAccount.vue'
import { bus } from 'shared/state/Bus'

@Component({ components: { HeaderLinkAccount } })
export default class Header extends Vue {
	get myId() {
		return document.location.search.replace('?id=', '')
	}
}
